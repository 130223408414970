import request from "@/plugins/axios";

// 获取余额
export const apiFinanceDetail = (data: any) =>
  request.post("long_range_finance.Finance/detail", data);

// cumulativeTransactions
export const apiCumulativeTransactions = (data: any) =>
  request.post("long_range_finance.Finance/cumulativeTransactions", data);

// 结算金额
export const apiSettlementAmount = (data: any) =>
  request.post("long_range_finance.Finance/settlementAmount", data);

//获取余额变动列表

export const apiFinanceLists = (params: any) =>
  request.get("long_range_finance.Finance/lists", { params });

//充值余额
export const apiFinanceAdd = (data: any) =>
  request.post("long_range_finance.Finance/add", data);

//获取余额变动列表字段映射
export const apiFinanceMcsBalanceListEnum = (params: any) =>
  request.get("long_range_finance.Finance/mcsBalanceListEnum", { params });

//财务申请记录
export const apiFinanceBalanceApplyLists = (params: any) =>
  request.get("long_range_finance.Finance/balanceApplyLists", { params });
