
































































































































































































































































import { RequestPaging } from "@/utils/util";
import { Component, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import DatePicker from "@/components/date-picker.vue";

import { apiCumulativeTransactions, apiFinanceDetail, apiSettlementAmount } from '@/api/financial'
@Component({
    components: {
        LsDialog,
        DatePicker,
    },
})
export default class Overview extends Vue {
    searchObj: any = {
        start_time: "",
        end_time: "",
    };

    financeData: any = {
        now_balance: "",
    };
    cumulativeTransactions: any = {
      after_sale_count: 0,
      order_count: 0,
      order_price_total: 0.00,
      after_sale_price_total: 0.00,
    };
  settlementAmount: any = {
    settlementNotPaidPrice: 0.00,
    supplierPendingSettlement: 0.00,
    settlementPriceCount: 0.00,
    platformSettlementAmount: 0.00,
    supplierPendingSettlementPrice: 0.00,
    };

    goto(path: string) {
        this.$router.push({
            path: path,
        });
    }
  chageDate(val: any) {
    console.log(234234234)
      this.searchObj.start_time = val[0];
      this.searchObj.end_time = val[1];
      this.getCumulativeTransactions();
    }
    //预付款账户
    async getFinanceDetail() {
        const res = await apiFinanceDetail({});
        if (res) {
            this.financeData = res;
        }
    }
    //累计交易
    async getCumulativeTransactions() {
      console.log(this.searchObj,'his.searchObj')
        const res = await apiCumulativeTransactions(this.searchObj);
        if (res) {
            this.cumulativeTransactions = res;
        }
    }
    //累计交易
    async getSettlementAmount() {
        const res = await apiSettlementAmount({});
        if (res) {
            this.settlementAmount = res;
        }
    }

    created() {
        // 预付款账户
        this.getFinanceDetail();
        this.getCumulativeTransactions();
        this.getSettlementAmount();
    }
}
